import {
  Link,
  Button,
  FlashCard,
  GridLayout,
  TypographyScale,
  ESingleImageVariant,
} from '@mdb/flora';
import theme from '@mdb/flora/theme';
import { useState } from 'react';
import { TrackDetails } from 'keen-slider';
import { Carousel } from '@mdb/lms-components';

import styles from './styles';

interface StudentProjectsProps {
  data: {
    header: string;
    subheader: string;
    links: {
      [key: string]: {
        url: string;
        text: string;
      };
    };
    cards: Array<{
      title: string;
      intro: string;
      imgSrc: string;
      cta: {
        url: string;
        text: string;
      };
    }>;
  };
}

export default function StudentProjects({
  data: { header, subheader, links, cards },
}: StudentProjectsProps) {
  const [trackDetails, setTrackDetails] = useState<TrackDetails | null>(null);

  return (
    <GridLayout>
      <section sx={styles.StudentProjectsWrapper}>
        <div sx={styles.StudentProjectsHeader}>
          <TypographyScale variant="heading4">{header}</TypographyScale>
          <TypographyScale
            sx={styles.StudentProjectsSubheader}
            variant="body1"
            color="secondary"
          >
            {subheader}
          </TypographyScale>
          <div sx={styles.StudentProjectsLinks}>
            <Button href={links.devcenterProjects.url}>
              {links.devcenterProjects.text}
            </Button>
            <Link linkIcon="arrow" href={links.majorLeagueHacking.url}>
              {links.majorLeagueHacking.text}
            </Link>
          </div>
        </div>
        <Carousel
          paginationInverse
          setTrackDetails={setTrackDetails}
          sx={styles.StudentProjectsCarouselWrapper}
        >
          {cards.map((card, idx) => {
            const active = trackDetails?.rel === idx;
            return (
              <FlashCard
                key={card.title}
                imageryType="image"
                imageConfig={{
                  src: card.imgSrc,
                  variant: ESingleImageVariant.VIDEO,
                }}
                title={card.title}
                text={card.intro}
                customVariant="heading6"
                cta={{
                  type: 'link-chevron',
                  text: card.cta.text,
                  config: {
                    href: card.cta.url,
                  },
                }}
                customHeaderStyles={styles.StudentProjectsCarouselCardHeader}
                customLinkStyles={{
                  transition: 'border 0.5s ease-in-out',
                  ...(active && {
                    border: `1px solid ${theme.colors.green80}`,
                  }),
                }}
              />
            );
          })}
        </Carousel>
      </section>
    </GridLayout>
  );
}
