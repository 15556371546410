import { ThemeUICSSObject } from 'theme-ui';

const StudentProjectsWrapper: ThemeUICSSObject = {
  paddingTop: ['inc90', null, 'inc100', 'inc130'],
  gridColumn: ['span 6', 'span 6', 'span 8', 'span 12'],
  '.pagination-wrapper': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 'inc30',
  },
  '.carousel-control': {
    display: ['none', null, null, 'initial'],
  },
  '.keen-slider__slide > div > div': {
    px: ['inc20', null, null, 0],
  },
};

const StudentProjectsHeader: ThemeUICSSObject = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  h4: {
    marginBottom: 'inc30',
  },
};

const StudentProjectsSubheader: ThemeUICSSObject = {
  textAlign: 'center',
  width: ['100%', null, '68%', '100%'],
};

const StudentProjectsLinks: ThemeUICSSObject = {
  display: 'flex',
  flexDirection: ['column', null, null, 'row'],
  alignItems: 'center',
  marginTop: ['inc40', null, 'inc50'],
  textAlign: 'center',
  '> span': {
    marginBottom: ['inc30', null, null, 0],
    marginRight: [0, null, null, 'inc50'],
  },
};

const StudentProjectsCarouselWrapper: ThemeUICSSObject = {
  marginTop: ['inc70', null, 'inc50', 'inc90'],
  marginBottom: ['inc20', null, 'inc40', 'inc60'],
};

const StudentProjectsCarouselCardHeader: ThemeUICSSObject = {
  maxWidth: ['100%', null, null, '334px'],
  img: {
    height: '177px',
  },
};

const styles = {
  StudentProjectsWrapper,
  StudentProjectsHeader,
  StudentProjectsSubheader,
  StudentProjectsLinks,
  StudentProjectsCarouselWrapper,
  StudentProjectsCarouselCardHeader,
};

export default styles;
